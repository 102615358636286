import Model from "../Model";

class Area extends Model {
    buildUrl ({ param }) {
        return ['areas', param];
    }

    async byId(form) {
        return await this.request({
            method: 'POST',
            url: 'areas',
            data: form
        });
    }

    async store(form) {
        return await this.request({
            method: 'POST',
            url: 'areas/store',
            data: form
        });
    }

    async update(form) {
        return await this.request({
            method: 'POST',
            url: 'areas/update',
            data: form
        });
    }

    async destroy(form) {
        return await this.request({
            method: 'POST',
            url: 'areas/destroy',
            data: form
        });
    }
}

export default new Area;